<template>
  <div id="login">
    <div class="container">
      <card-component>
        <img src="/img/sgara-icon.svg" alt="Sgara Logo" class="login-logo">
        <ValidationObserver ref="form">
          <form @submit.prevent="submit">
            <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
              <b-field class="mb-2" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input type="email" id="email" v-model="user.email" placeholder="Enter your Email"></b-input>
              </b-field>
            </ValidationProvider>
            <ValidationProvider name="Password" rules="required" v-slot="{ errors }" vid="password">
              <b-field class="mb-2" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input type="password" id="password" v-model="user.password"
                         placeholder="Enter your Password"></b-input>
              </b-field>
            </ValidationProvider>

            <div>
              <b-button type="is-primary" expanded class="my-3" native-type="submit">Login</b-button>
            </div>
          </form>
        </ValidationObserver>
      </card-component>
    </div>
  </div>
</template>

<style lang="scss">
#login {
  max-width: 500px;
  margin: 5rem auto;

  .login-logo {
    height: 32px;
    margin-bottom: 1rem;
  }
}
</style>
<script>
import CardComponent from "@/components/CardComponent";
import {mapActions} from "vuex";

export default {
  name: "Login",
  components: {CardComponent},
  data: () => {
    return {
      user: {},
    }
  },
  methods: {
    ...mapActions('auth', [
      'login',
    ]),

    submit() {
      this.$loading()
      this.login(this.user).then(res => {
        this.$router.push('/book/overview')
      }).finally((() => {
        this.$loading(false)
      }))
    }
  }
}
</script>
